const baseurl={
    "login":{
        "getRedirectUrl":"/SSOServer/sso/getRedirectUrl",
        "doLogin":"/SSOServer/sso/doLogin",
        "kaptcha":"/SSOServer/sso/defaultKaptcha/"
    },
    "zhuce":{
        "jyusername":"/SSOServer/sso/jyusername/",
        "UpdataAndLoadwxzhuceimg":"/SSOServer/sso/UpdataAndLoadwxzhuceimg/",
        "querwxAuthorization":"/SSOServer/sso/querwxAuthorization/",
        "wxloginimg":"/SSOServer/sso/wxloginimg/"
    },
    "info":{
        "getmeinfo":"/SSOServer/info/getmeinfo/"
    }
}

export default baseurl;