import React from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';
import 'react-calendar-heatmap/dist/styles.css';
import './me.css';

import { LeftOutlined } from '@ant-design/icons';
import { Avatar ,Descriptions,Timeline ,Divider,Skeleton,Button  } from 'antd';

import baseurl from '../baseurl';
import {request} from '../util/helper';

class Me extends React.Component{
    constructor(props){
        super();
        this.state={
            year:'',
            tjmap:[],
            five:[],
            userinfo:{},
            loading:true,
            horizontal:true
        }
        this.intif=this.intif.bind(this);
        this.browserRedirect=this.browserRedirect.bind(this);
    }
    componentDidMount(){
        this.intif();
        this.browserRedirect();
    }
    browserRedirect() {
        var sUserAgent = navigator.userAgent.toLowerCase();
        var bIsIpad = sUserAgent.match(/ipad/i)!== null;
        var bIsIphoneOs = sUserAgent.match(/iphone os/i) !== null;
        var bIsMidp = sUserAgent.match(/midp/i)!== null;
        var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) !== null;
        var bIsUc = sUserAgent.match(/ucweb/i) !== null;
        var bIsAndroid = sUserAgent.match(/android/i) !== null;
        var bIsCE = sUserAgent.match(/windows ce/i) !== null;
        var bIsWM = sUserAgent.match(/windows mobile/i) !== null;
        if (!(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) ){
            this.setState({
                horizontal:true
            })
        }
        else{
            this.setState({
                horizontal:false
            })
        }
        
    }
    intif(){
        request({
            url:baseurl.info.getmeinfo,
            method:'post',
          }).then(res=>{
            console.log(res);
            if(res&&res.code===401){
                let baurl=window.location.protocol+'//'+window.location.host
                window.location.href = decodeURIComponent(baurl+'/login?redirect='+baurl+'/me');
            }else if(res.data){
                this.setState({
                    year:res.data.year,
                    tjmap:res.data.tjmap,
                    five:res.data.five,
                    userinfo:res.data.userinfo,
                    loading:false
                });
                
            }
          });
    }

    render(){
        return  <div className='divbody'>
                    <Skeleton active avatar
                    loading={this.state.loading}
                    paragraph={{
                        rows: 20,
                      }}
                    >
                    <div className='divcomcent'>
                        <Button type="link" icon={<LeftOutlined />} onClick={()=>{window.history.go(-1)}} >返回</Button>
                        <div className='userclass'>
                            <Avatar
                                size={{
                                xs: 30,
                                sm: 50,
                                md: 100,
                                lg: 150,
                                xl: 200,
                                xxl: 250,
                                }}
                                src={this.state.userinfo.avatarurl}
                            />
                            <h3>{this.state.userinfo.nickname}</h3>
                        </div>
                        <Divider orientation="left">信息</Divider>
                        <Descriptions >
                            <Descriptions.Item label="用户名">{this.state.userinfo.username}</Descriptions.Item>
                            <Descriptions.Item label="注册时间">{this.state.userinfo.registeredtime}</Descriptions.Item>
                            <Descriptions.Item label="手机号">{this.state.userinfo.phoneno}</Descriptions.Item>
                            <Descriptions.Item label="邮箱">{this.state.userinfo.email}</Descriptions.Item>
                            <Descriptions.Item label="推送开关标志">{this.state.userinfo.pushflag}</Descriptions.Item>
                            <Descriptions.Item label="推送地址">{this.state.userinfo.pushurl}</Descriptions.Item>
                        </Descriptions>
                        <Divider orientation="left">时间线</Divider>
                        <Timeline>
                            {
                                this.state.five.map((item,index)=>{
                                    return <Timeline.Item key={index}>{item.detailtime+' 登录 '+item.url}</Timeline.Item>
                                })
                            }
                        </Timeline>
                        <Divider orientation="left">统计</Divider>
                        <CalendarHeatmap
                            horizontal={this.state.horizontal}
                            startDate={new Date(this.state.year+'-01-01')}
                            endDate={new Date(this.state.year+'-12-31')}
                            values={this.state.tjmap}
                            monthLabels={["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"]}
                            showWeekdayLabels={true}
                            weekdayLabels={["周日","周一","周二","周三","周四","周五","周六"]}
                            classForValue={(value) => {
                                if (!value) {
                                return 'color-scale-0';
                                }else if(value.count>=12){
                                    return 'color-scale-4';
                                }else if(value.count>=8){
                                    return 'color-scale-3';
                                }else if(value.count>=4){
                                    return 'color-scale-2';
                                }else if(value.count>=1){
                                    return 'color-scale-1';
                                }else{
                                    return 'color-scale-0';
                                }
                                // return `color-scale-${value.count}`;
                            }}
                            tooltipDataAttrs={value => {
                                if(value.count>0){
                                    return {
                                        'data-tip':value.date+ '登录:'+ value.count+'次'
                                    };
                                }
                                
                            }}
                        />
                        <ReactTooltip />
                        <div className='footer' style={{ textAlign: 'center',width:'100%',height:'60px','paddingTop':'20px' ,}}>
                            yiqiulove.cn ©2022 Created by yiqiu <a href="https://beian.miit.gov.cn">皖ICP备2021012075号</a>     
                        </div>
                    </div>
                    </Skeleton>
                    
            </div>
    }
}

export default Me;