import React from 'react';
import "./register.css"
import 'antd/dist/antd.min.css';
import { Button, Form,message, Input,Modal,Divider} from 'antd';
import { UserOutlined ,LockOutlined,CheckCircleOutlined,CloseCircleOutlined} from '@ant-design/icons';

import {debounce} from "../util/util"
import {request} from '../util/helper';
import baseurl from '../baseurl';

var Interval;
var Timeout;

class Register extends React.Component{
    constructor(props){
        super();
        this.state={
            Modalopen:false,
            Modalauthorimgflag:true,
            Modalauthorimgtitle:"",
            inputusernameflag:'',
            Authorization:{},
        }
        this.usernamechange=this.usernamechange.bind(this);
        this.authorization=this.authorization.bind(this);
    }
    usernamechange(value){
        if(!value){
            this.setState({
                inputusernameflag:''
            })
        }else{
            request({
                url:baseurl.zhuce.jyusername,
                method:'post',
                disloading:true,
                data:{
                    "username":value,
                }
            }).then((res)=>{
                if(res.code === 200) {
                    this.setState({
                        inputusernameflag:'T'
                    })
                  }  else {
                    message.error("用户名已存在");
                    this.setState({
                        inputusernameflag:'N'
                    })
                  }
            })
            
        }
    }
    authorization(){
        clearInterval(Interval);
        Interval=setInterval(()=>{
            request({
                url:baseurl.zhuce.querwxAuthorization,
                method:'post',
                disloading:true,
                data:{
                    "key":this.state.Authorization.key,
                }
            }).then((res)=>{
                if(res.code === 200) {
                    let authorizationdata=res.data;
                    switch(authorizationdata.state)
                    {
                        case '1':
                            console.log("已扫描");
                            this.setState({
                                Modalauthorimgflag:false,
                                Modalauthorimgtitle:"已扫描"
                            })
                            break;
                        case "2":
                            console.log("已确认");
                            clearInterval(Interval);
                            clearTimeout(Timeout);
                            this.setState({
                                Modalopen:false
                            });
                            setTimeout(function() {
                                window.history.go(-1);
                              }, 800);
                            message.success("注册成功");
                            break;
                        case "3":
                            console.log("已取消");
                            clearInterval(Interval);
                            clearTimeout(Timeout);
                            this.setState({
                                Modalopen:false
                            });
                            message.info("您以取消注册");
                            break;
                        default:
                           
                    }

                }else{
                    clearInterval(Interval);
                    clearTimeout(Timeout);
                    this.setState({
                        Modalauthorimgflag:false,
                        Modalauthorimgtitle:"已失效"
                    })
                }
            })
        },1000)
        clearTimeout(Timeout);
        Timeout=setTimeout(()=>{
            clearInterval(Interval);
        },70000)
    }
    componentWillUnmount(){
        clearTimeout(Timeout);
        clearInterval(Interval);
    }
    
    render(){
        const onFinish = (values) => {
            let {username,password}=values;
            if(this.state.inputusernameflag==='N'){
                message.error("请输入正确用户名");
                return
            }
            request({
                url:baseurl.zhuce.UpdataAndLoadwxzhuceimg,
                method:'post',
                data:{
                    "username":username,
                    "password":password
                }
            }).then((res)=>{
                if(res.code===200){
                    console.log(res.data.key);
                    this.setState({
                        Authorization:res.data,
                        Modalopen:true,
                        Modalauthorimgflag:true,
                        Modalauthorimgtitle:""
                    })
                    this.authorization();
                }else{
                    message.error("系统错误,请刷新后重试")
                }
                
            })

        };
        const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        };
        const handleCancel = () => {
            this.setState({
                Modalopen:false
            })
        };
        const debouncechangeusername=debounce(this.usernamechange,800);
        return(
            <div className='view-box'>
                <div className='water'></div>
                <div className='content-box'>
                <Form
                    style={{"height":"100%"}}
                    labelAlign="left"
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    >
                    <Form.Item
                        label="用户名"
                        name="username"
                        rules={[
                        {
                            required: true,
                            message: '请输入用户名!',
                        },
                        ]}
                    >
                        <Input
                            onChange={({ target: { value }})=>{
                                debouncechangeusername(value);
                            }}
                            suffix={
                                this.state.inputusernameflag===''? 
                                null: this.state.inputusernameflag==='T'? 
                                <CheckCircleOutlined style={{color: '#2ecc71' }}/>:<CloseCircleOutlined style={{color: '#e84118' }}/>
                                } 
                            prefix={<UserOutlined />}/>
                    </Form.Item>
                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: '请输入密码!',
                        },
                        ]}
                    >
                        <Input.Password prefix={<LockOutlined />}/>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                        span: 24,
                        }}
                    >
                        <Button  block type="primary" htmlType="submit">
                            注册
                        </Button>
                    </Form.Item>
                    <Form.Item
                      wrapperCol={{
                        span: 24,
                      }}
                    >
                        <Button style={{"float":"left"}} type="link" onClick={()=>{window.history.go(-1);}} >返回</Button>
                        <Button style={{"float":"right"}} type="link" htmlType="reset" >重置</Button>
                        
                    </Form.Item>
                    </Form>
                    <Modal
                        open={this.state.Modalopen}
                        onCancel={handleCancel}
                        footer={null}
                        width={300}
                    >
                        <div  style={{"textAlign":"center"}}>
                                <div ><img
                                  alt=''
                                  width={200}
                                  height={200}
                                  src={this.state.Authorization.img_base64}
                                  />
                                    <div className='saoyisaoimg1'>
                                        {this.state.Modalauthorimgflag? 
                                        <img
                                        alt=''
                                        width={200}
                                        height={200}
                                        src="./saoyisao.png"
                                        />:<div className='saoyisaoshixiao'><span className='saoyisaoshixiaotitle'>{this.state.Modalauthorimgtitle}</span></div>    
                                    }
                                        
                                    </div>
                                  </div>
                                
                                <Divider >扫一扫绑定微信注册</Divider>
                              </div>
                    </Modal>
                </div>
                <div className='headertop'>
                    <div className='login-form-div-logo' >
                        <div className="spinner">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        </div>
                        <div id='webtitle'>
                        <span style={{"fontSize":"30px"}}>统一认证中心</span>
                        </div>
                    </div>
                </div>
                <div className='footbottom'>
                    This page is provided by yiqiulove.cn <span> </span>
                    <a  href="https://beian.miit.gov.cn" rel="noopener noreferrer" target="_blank">皖ICP备2021012075号</a>
                </div>
            </div>
        )
    }

}
export default Register;