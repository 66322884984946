import React from 'react';
import ReactDom from 'react-dom';
import './login.css';
import 'antd/dist/antd.min.css';
import { Button ,Input,message,Form,Tabs,Divider  } from 'antd';
import baseurl from '../baseurl';
import {request} from '../util/helper';
import { UserOutlined ,LockOutlined,CommentOutlined, FileImageOutlined} from '@ant-design/icons';

function getParam(name, defaultValue){
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] === name){return pair[1] + (pair[2] ? '=' + pair[2] : '');}
	}
	return(defaultValue === undefined ? null : defaultValue);
}

var Interval;
var Timeout;

class Login extends React.Component{
    constructor(props){
        super();
        this.state={
          Authorization:{},
          Modalauthorimgflag:false,
          Modalauthorimgtitle:"加载中"
        }
        this.loginclick=this.loginclick.bind(this);
        this.onclickkaptcha=this.onclickkaptcha.bind(this);
        this.authorization=this.authorization.bind(this);
        this.intif=this.intif.bind(this);
    }
    authorization(){
      clearInterval(Interval);
      Interval=setInterval(()=>{
          request({
              url:baseurl.zhuce.querwxAuthorization,
              method:'post',
              disloading:true,
              data:{
                  "key":this.state.Authorization.key,
              }
          }).then((res)=>{
              if(res.code === 200) {
                  let authorizationdata=res.data;
                  switch(authorizationdata.state)
                  {
                      case '1':
                          console.log("已扫描");
                          this.setState({
                              Modalauthorimgflag:false,
                              Modalauthorimgtitle:"已扫描"
                          })
                          break;
                      case "2":
                          clearInterval(Interval);
                          clearTimeout(Timeout);
                          // message.success("登录成功");
                          this.intif();
                          break;
                      case "3":
                          console.log("已取消");
                          clearInterval(Interval);
                          clearTimeout(Timeout);
                          message.info("您以取消登录");
                          break;
                      default:
                         
                  }

              }else{
                  clearInterval(Interval);
                  clearTimeout(Timeout);
                  this.setState({
                      Modalauthorimgflag:false,
                      Modalauthorimgtitle:"已失效"
                  })
              }
          })
      },1000)
      clearTimeout(Timeout);
      Timeout=setTimeout(()=>{
          clearInterval(Interval);
      },70000)
  }
    
    componentDidMount(){
      this.intif();
    }
    intif(){
      let redirect=getParam('redirect', '');
      if(redirect!==''){
        request({
          url:baseurl.login.getRedirectUrl,
          method:'post',
          data:{
            "redirect":getParam('redirect', ''),
            "mode":''
          }
        }).then((res)=>{
          console.log(res);
          if(res.code === 200) {
            // 已登录，并且redirect地址有效，开始跳转  
            window.location.href = decodeURIComponent(res.data);
          } else if(res.code === 401) {
            console.log('未登录');
            localStorage.removeItem("satoken");
          } else {
            message.error(res.msg);
          }
        })
      }else{
        message.error("无回调地址");
      }
    }
    onclickkaptcha(){
      let timestamp = (new Date()).valueOf();
      let kaptcha = document.getElementById('kaptcha');
      ReactDom.findDOMNode(kaptcha).src = baseurl.login.kaptcha+'?t='+timestamp
    }
    loginclick(values){
      let redirect=getParam('redirect', '');
      let{username,password,kaptcha}=values;
      request({
        url:baseurl.login.doLogin+"?name="+username+"&pwd="+password+"&kaptcha="+kaptcha+"&redirect="+redirect,
        method:'post',
        data:{
        }
      }).then(res=>{
        if(res.code === 200) {
          let data=res.data;
          localStorage.setItem('satoken', data.satoken);
          message.success("登录成功");
          setTimeout(function() {
            window.location.href = decodeURIComponent(data.redirectUrl);
          }, 800);
        } else {
          message.error(res.msg);
          this.onclickkaptcha()
        }
      })
    }
    render(){
        const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        };
        const onChange = (key) => {
          if(key==="2"){
            request({
              url:baseurl.zhuce.wxloginimg,
              method:'post',
              data:{
                redirect:getParam('redirect', ''),
              }
            }).then((res)=>{
                if(res.code===200){
                    this.setState({
                        Authorization:res.data,
                        Modalauthorimgflag:true,
                        Modalauthorimgtitle:""
                    })
                    this.authorization();
                }else{
                    message.error("系统错误,请刷新后重试")
                }
                
            })
          }else{
            clearInterval(Interval);
            clearTimeout(Timeout);
            this.setState({
              Authorization:{},
              Modalauthorimgflag:false,
              Modalauthorimgtitle:"加载中"
            })
          }
        };
        return(
          <div className="view-box">
            <div className='waterlogin'></div>
            <div className="content-boxlogin">
            <Tabs
                centered={true}
                defaultActiveKey="1"
                onChange={onChange}
                items={[
                  {
                    label: (
                      <span>
                        <LockOutlined />
                          账号密码登录
                      </span>
                    ),
                    key: '1',
                    children: <Form
                                style={{"height":"100%"}}
                                labelAlign="left"
                                name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={(values)=>this.loginclick(values)}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                                >
                                <Form.Item
                                    label="用户名"
                                    name="username"
                                    rules={[
                                    {
                                        required: true,
                                        message: '请输入用户名!',
                                    },
                                    ]}
                                >
                                    <Input prefix={<UserOutlined />}/>
                                </Form.Item>
              
                                <Form.Item
                                    label="密码"
                                    name="password"
                                    rules={[
                                    {
                                        required: true,
                                        message: '请输入密码!',
                                    },
                                    ]}
                                >
                                    <Input.Password prefix={<LockOutlined />} />
                                </Form.Item>
                                <Form.Item
                                    label={<img id="kaptcha" width='100px' height='45px' src={baseurl.login.kaptcha} alt="" onClick={this.onclickkaptcha}></img>}
                                    name="kaptcha"
                                    rules={[
                                    {
                                        required: true,
                                        message: '请输入验证码!',
                                    },
                                    ]}
                                    wrapperCol={{
                                    span: 24,
                                    }}
                                >
                                  <Input   prefix={<FileImageOutlined />}/>
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                    span: 24,
                                    }}
                                >
                                    <Button block  type="primary" htmlType="submit">
                                        登录
                                    </Button>
                                </Form.Item>
                                <Form.Item
                                    wrapperCol={{
                                      offset: 11,
                                      span: 13,
                                    }}
                                >
                                    <Button type="link" href="/register" icon={<UserOutlined />}>没有账户？点击注册</Button>
                                </Form.Item>
                              </Form>,
                  },
                  {
                    label:  (
                      <span>
                        <CommentOutlined/>
                          微信扫码登录
                      </span>
                    ),
                    key: '2',
                    children: <div  style={{"textAlign":"center"}}>
                                <div ><img
                                  alt=''
                                  width={200}
                                  height={200}
                                  src={this.state.Authorization.img_base64}
                                  />
                                    <div className='saoyisaoimg'>
                                    {this.state.Modalauthorimgflag? 
                                        <img
                                        alt=''
                                        width={200}
                                        height={200}
                                        src="./saoyisao.png"
                                        />:<div className='saoyisaoshixiao'><span className='saoyisaoshixiaotitle'>{this.state.Modalauthorimgtitle}</span></div>    
                                    }
                                    </div>
                                  </div>
                                
                                <Divider >打开微信扫一扫以登录</Divider>
                              </div>,
                  },
                ]}
              />
              
            </div>
            <div className='headertop'>
              <div className='login-form-div-logo' >
                <div className="loginspinner">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div id='webtitle'>
                  <span style={{"fontSize":"30px"}}>统一认证中心</span>
                </div>
              </div>
            </div>
            <div className='footbottom'>
              This page is provided by yiqiulove.cn <span> </span>
              <a  href="https://beian.miit.gov.cn" rel="noopener noreferrer" target="_blank">皖ICP备2021012075号</a>
            </div>
          </div>
        );
    }
}

export default Login;