import React from 'react';
import 'antd/dist/antd.min.css';

import { Result } from 'antd';

class Notfind extends React.Component{
    constructor(props){
        super();
        this.state={
        }
    }
    render(){
        return(
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
            />
        )
    }

}

export default Notfind;