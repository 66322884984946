import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Notfind from './404/notfind'
import Login from './login/logon';
import Me from './me/me';
import Register from './register/register';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
        <Switch>
          <Route exact path="/login" component={Login}/>
          <Route exact path="/register" component={Register}/>
          <Route exact path="/me" component={Me}/>
          <Route path="*" component={Notfind}/>
        </Switch>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
